import { useAnimate } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { Link } from "react-router-dom";

const COUNTDOWN_FROM = "2025-02-21T00:00:00+06:00";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const toBanglaNumber = (number) => {
  const banglaNumbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
  return number
    .toString()
    .split("")
    .map((digit) => banglaNumbers[digit] || digit)
    .join("");
};

const ShiftingCountdown = () => {
  return (
    <div className="tw-bg-gradient-to-br tw-from-[#FFCE39] tw-to-[#FFCE39] tw-p-1">
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-items-center">
        {/* Text section - centered on mobile, left on md+ */}
        <p className="tw-text-black tw-font-bold tw-text-center md:tw-text-center tw-text-xl md:tw-text-xl xl:tw-text-2xl">
          🚀🎉একুশ উৎসবে ভর্তি হলেই নিশ্চিত উপহার
        </p>

        {/* Countdown section - centered, reduced width on larger screens */}
        <div className="tw-flex tw-justify-end">
          <div className="tw-flex tw-w-full md:tw-w-4/5 tw-bg-white tw-p-2 tw-rounded-lg tw-shadow-md tw-gap-2">
            <CountdownItem unit="Day" text="দিন" />
            <CountdownItem unit="Hour" text="ঘণ্টা" />
            <CountdownItem unit="Minute" text="মিনিট" />
            <CountdownItem unit="Second" text="সেকেন্ড" />
          </div>
        </div>

        {/* Button section - centered on mobile, right-aligned on md+ */}
        {/* <div className="tw-flex tw-justify-center md:tw-justify-center ">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdeJN1_o0hcMaFnBcUbW3Lm-iA9wNRbMsfxjTVRaXVhIEeNWQ/viewform"
            className="tw-no-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="tw-w-[170px] tw-py-2 tw-rounded-3xl tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center tw-text-white">
              <span className="tw-text-lg tw-font-semibold tw-mr-2">
                ক্লিক করো
              </span>
              <HiOutlineExternalLink className="tw-text-lg tw-font-semibold" />
            </button>
          </a>
        </div> */}
        <div className="tw-flex tw-justify-center md:tw-justify-end md:tw-mr-4">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdeJN1_o0hcMaFnBcUbW3Lm-iA9wNRbMsfxjTVRaXVhIEeNWQ/viewform"
            className="tw-no-underline tw-animate-bounce"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="tw-w-[170px] tw-py-2 tw-rounded-3xl tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center tw-text-white">
              <span className="tw-text-lg tw-font-semibold tw-mr-2">
                ক্লিক করো
              </span>
              <HiOutlineExternalLink className="tw-text-lg tw-font-semibold" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

const CountdownItem = ({ unit, text }) => {
  const { ref, time } = useTimer(unit);

  return (
    <div className="tw-flex tw-h-12 tw-w-full md:tw-w-1/4 tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-r-[1px] last:tw-border-r-0 tw-border-slate-200 tw-font-mono">
      <div className="tw-relative tw-w-full tw-overflow-hidden tw-text-center">
        <span
          ref={ref}
          className="tw-block tw-text-2xl tw-font-medium tw-text-black"
        >
          {toBanglaNumber(time)}
        </span>
      </div>
      <span className="tw-text-xs tw-font-light tw-text-slate-700">{text}</span>
    </div>
  );
};

const useTimer = (unit) => {
  const [ref, animate] = useAnimate();
  const intervalRef = useRef(null);
  const timeRef = useRef(0);
  const [time, setTime] = useState(0);

  useEffect(() => {
    const handleCountdown = async () => {
      const end = new Date(COUNTDOWN_FROM);
      const now = new Date();
      const distance = +end - +now;

      let newTime = 0;

      if (unit === "Day") {
        newTime = Math.floor(distance / DAY);
      } else if (unit === "Hour") {
        newTime = Math.floor((distance % DAY) / HOUR);
      } else if (unit === "Minute") {
        newTime = Math.floor((distance % HOUR) / MINUTE);
      } else {
        newTime = Math.floor((distance % MINUTE) / SECOND);
      }

      if (newTime !== timeRef.current) {
        try {
          await animate(
            ref.current,
            { y: ["0%", "-50%"], opacity: [1, 0] },
            { duration: 0.35 }
          );
          timeRef.current = newTime;
          setTime(newTime);

          await animate(
            ref.current,
            { y: ["50%", "0%"], opacity: [0, 1] },
            { duration: 0.35 }
          );
        } catch (error) {
          console.error("Animation error:", error);
        }
      }
    };

    intervalRef.current = setInterval(handleCountdown, 1000);

    return () => clearInterval(intervalRef.current || undefined);
  }, [animate, ref, unit]);

  return { ref, time };
};

export default ShiftingCountdown;

//////////////////////////////////////////

// import { useAnimate } from "framer-motion";
// import { useEffect, useRef, useState } from "react";
// import { HiOutlineExternalLink } from "react-icons/hi";
// import { Link } from "react-router-dom";

// const COUNTDOWN_FROM = "2025-02-21T00:00:00+06:00";

// const SECOND = 1000;
// const MINUTE = SECOND * 60;
// const HOUR = MINUTE * 60;
// const DAY = HOUR * 24;

// // Convert English numbers to Bangla
// const toBanglaNumber = (number) => {
//   const banglaNumbers = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
//   return number
//     .toString()
//     .split("")
//     .map((digit) => banglaNumbers[digit] || digit)
//     .join("");
// };

// const ShiftingCountdown = () => {
//   return (
//     <div className="tw-bg-gradient-to-br tw-bg-[#FFCE39] tw-p-2 tw-flex tw-items-center tw-justify-center">
//       <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-w-full tw-flex-col md:tw-flex-row tw-items-center tw-justify-around md:tw-gap-4 tw-bg-red-500">
//         <p className="tw-text-black tw-font-bold tw-text-center md:tw-text-left md:tw-mt-0 tw-text-lg">
//           🚀🎉একুশ উৎসবে ভর্তি হলেই নিশ্চিত উপহার 🚀🎉
//         </p>
//         <div className="tw-flex tw-w-full  tw-bg-white tw-p-2 tw-rounded-lg tw-shadow-md tw-flex-row tw-gap-2">
//           <CountdownItem unit="Day" text="দিন" />
//           <CountdownItem unit="Hour" text="ঘণ্টা" />
//           <CountdownItem unit="Minute" text="মিনিট" />
//           <CountdownItem unit="Second" text="সেকেন্ড" />
//         </div>

//         <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdeJN1_o0hcMaFnBcUbW3Lm-iA9wNRbMsfxjTVRaXVhIEeNWQ/viewform">
//           {/* <button className="tw-w-full md:tw-w-[170px] md:tw-mx-auto tw-py-2 tw-rounded-3xl tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center tw-text-white"> */}
//           <button className="tw-w-full md:tw-w-[170px] md:tw-mx-auto tw-py-2 tw-rounded-3xl tw-bg-[#532D80] tw-flex tw-justify-center tw-items-center tw-text-white">
//             <span className="tw-text-lg tw-font-semibold tw-mr-2">
//               Registration
//             </span>
//             <HiOutlineExternalLink className="tw-text-lg tw-font-semibold" />
//           </button>
//         </Link>
//       </div>
//     </div>
//   );
// };

// const CountdownItem = ({ unit, text }) => {
//   const { ref, time } = useTimer(unit);

//   return (
//     <div className="tw-flex tw-h-12 tw-w-full md:tw-w-1/4 tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-r-[1px] last:tw-border-r-0 tw-border-slate-200 tw-font-mono">
//       <div className="tw-relative tw-w-full tw-overflow-hidden tw-text-center">
//         <span
//           ref={ref}
//           className="tw-block tw-text-2xl tw-font-medium tw-text-black"
//         >
//           {toBanglaNumber(time)}
//         </span>
//       </div>
//       <span className="tw-text-xs tw-font-light tw-text-slate-700">{text}</span>
//     </div>
//   );
// };

// const useTimer = (unit) => {
//   const [ref, animate] = useAnimate();
//   const intervalRef = useRef(null);
//   const timeRef = useRef(0);
//   const [time, setTime] = useState(0);

//   useEffect(() => {
//     const handleCountdown = async () => {
//       const end = new Date(COUNTDOWN_FROM);
//       const now = new Date();
//       const distance = +end - +now;

//       let newTime = 0;

//       if (unit === "Day") {
//         newTime = Math.floor(distance / DAY);
//       } else if (unit === "Hour") {
//         newTime = Math.floor((distance % DAY) / HOUR);
//       } else if (unit === "Minute") {
//         newTime = Math.floor((distance % HOUR) / MINUTE);
//       } else {
//         newTime = Math.floor((distance % MINUTE) / SECOND);
//       }

//       if (newTime !== timeRef.current) {
//         try {
//           await animate(
//             ref.current,
//             { y: ["0%", "-50%"], opacity: [1, 0] },
//             { duration: 0.35 }
//           );
//           timeRef.current = newTime;
//           setTime(newTime);

//           await animate(
//             ref.current,
//             { y: ["50%", "0%"], opacity: [0, 1] },
//             { duration: 0.35 }
//           );
//         } catch (error) {
//           console.error("Animation error:", error);
//         }
//       }
//     };

//     intervalRef.current = setInterval(handleCountdown, 1000);

//     return () => clearInterval(intervalRef.current || undefined);
//   }, [animate, ref, unit]);

//   return { ref, time };
// };

// export default ShiftingCountdown;

// ============================================================
// import { useAnimate } from "framer-motion";
// import { useEffect, useRef, useState } from "react";
// import YearEndImage from "../../../../images/promotions/Logo.webp";

// // NOTE: Change this date to whatever date you want to countdown to :)
// const COUNTDOWN_FROM = "2025-02-21T00:00:00+06:00";

// const SECOND = 1000;
// const MINUTE = SECOND * 60;
// const HOUR = MINUTE * 60;
// const DAY = HOUR * 24;

// const ShiftingCountdown = () => {
//   return (
//     <div className="tw-bg-gradient-to-br tw-bg-[#FFCE39] tw-p-2 tw-flex tw-items-center tw-justify-center">
//       <div className="tw-flex tw-w-full tw-max-w-5xl tw-flex-col md:tw-flex-row tw-items-center tw-justify-between md:tw-gap-4">
//         {/* Add image */}
//         {/* <img
//           src={YearEndImage} // Replace with the actual image path
//           alt="Countdown Banner"
//           className="tw-w-full md:tw-w-1/4 tw-h-16 tw-object-contain" // Adjust width and styling as needed
//           style={{ objectFit: "contain" }}
//         /> */}
//         <p className="tw-text-black tw-font-bold tw-text-center md:tw-text-left md:tw-mt-0 tw-text-lg">
//           একুশ উৎসবে ভর্তি হলেই উপহার
//         </p>
//         {/* Countdown Items */}
//         <div className="tw-flex tw-w-full md:tw-w-2/3 tw-bg-white tw-p-2 tw-rounded-lg tw-shadow-md tw-flex-row tw-gap-2">
//           <CountdownItem unit="Day" text="days" />
//           <CountdownItem unit="Hour" text="hours" />
//           <CountdownItem unit="Minute" text="minutes" />
//           <CountdownItem unit="Second" text="seconds" />
//         </div>
//       </div>
//     </div>
//   );
// };

// const CountdownItem = ({ unit, text }) => {
//   const { ref, time } = useTimer(unit);

//   return (
//     <div className="tw-flex tw-h-12 tw-w-full md:tw-w-1/4 tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-r-[1px] last:tw-border-r-0 tw-border-slate-200 tw-font-mono">
//       <div className="tw-relative tw-w-full tw-overflow-hidden tw-text-center">
//         <span
//           ref={ref}
//           className="tw-block tw-text-2xl tw-font-medium tw-text-black"
//         >
//           {time}
//         </span>
//       </div>
//       <span className="tw-text-xs tw-font-light tw-text-slate-700">{text}</span>
//     </div>
//   );
// };

// export default ShiftingCountdown;

// const useTimer = (unit) => {
//   const [ref, animate] = useAnimate();
//   const intervalRef = useRef(null);
//   const timeRef = useRef(0);
//   const [time, setTime] = useState(0);

//   useEffect(() => {
//     const handleCountdown = async () => {
//       const end = new Date(COUNTDOWN_FROM);
//       const now = new Date();
//       const distance = +end - +now;

//       let newTime = 0;

//       if (unit === "Day") {
//         newTime = Math.floor(distance / DAY);
//       } else if (unit === "Hour") {
//         newTime = Math.floor((distance % DAY) / HOUR);
//       } else if (unit === "Minute") {
//         newTime = Math.floor((distance % HOUR) / MINUTE);
//       } else {
//         newTime = Math.floor((distance % MINUTE) / SECOND);
//       }

//       if (newTime !== timeRef.current) {
//         try {
//           // Exit animation
//           await animate(
//             ref.current,
//             { y: ["0%", "-50%"], opacity: [1, 0] },
//             { duration: 0.35 }
//           );
//           timeRef.current = newTime;
//           setTime(newTime);

//           // Enter animation
//           await animate(
//             ref.current,
//             { y: ["50%", "0%"], opacity: [0, 1] },
//             { duration: 0.35 }
//           );
//         } catch (error) {
//           console.error("Animation error:", error);
//         }
//       }
//     };

//     intervalRef.current = setInterval(handleCountdown, 1000);

//     return () => clearInterval(intervalRef.current || undefined);
//   }, [animate, ref, unit]);

//   return { ref, time };
// };

// ====================================================The above code working fine for english count down============================================================

// import { useAnimate } from "framer-motion";
// import { useEffect, useRef, useState } from "react";
// import YearEndImage from "../../../../images/promotions/YearEnd.jpg";

// // NOTE: Change this date to whatever date you want to countdown to :)
// const COUNTDOWN_FROM = "2025-01-01";

// const SECOND = 1000;
// const MINUTE = SECOND * 60;
// const HOUR = MINUTE * 60;
// const DAY = HOUR * 24;

// const ShiftingCountdown = () => {
//   return (
//     // <div className="tw-bg-gradient-to-br tw-from-violet-600 tw-to-indigo-600 tw-p-2 tw-flex tw-items-center tw-justify-center">
//     <div className="tw-bg-gradient-to-br tw-bg-[#ffb03a] tw-p-2 tw-flex tw-items-center tw-justify-center">
//       <div className="tw-flex tw-w-full tw-max-w-5xl tw-items-center tw-justify-between tw-gap-4">
//         {/* Add image */}
//         <img
//           src={YearEndImage} // Replace with the actual image path
//           alt="Countdown Banner"
//           className="tw-w-1/3 tw-rounded-lg tw-shadow-md tw-h-16" // Adjust width and styling as needed
//         />
//         <p className=" tw-text-white">
//           কোর্সে ভর্তি হয়ে ল্যাপটপ জেতার সুযোগ শেষ হচ্ছে আজই
//         </p>
//         {/* Countdown Items */}
//         <div className="tw-flex tw-w-2/3 tw-bg-white tw-p-2 tw-rounded-lg tw-shadow-md">
//           <CountdownItem unit="Day" text="days" />
//           <CountdownItem unit="Hour" text="hours" />
//           <CountdownItem unit="Minute" text="minutes" />
//           <CountdownItem unit="Second" text="seconds" />
//         </div>
//       </div>
//     </div>
//   );
// };

// const CountdownItem = ({ unit, text }) => {
//   const { ref, time } = useTimer(unit);

//   return (
//     <div className="tw-flex tw-h-6 tw-w-1/4 tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-r-[1px] tw-border-slate-200 tw-font-mono md:tw-h-6 md:tw-gap-2">
//       <div className="tw-relative tw-w-full tw-overflow-hidden tw-text-center">
//         <span
//           ref={ref}
//           className="tw-block tw-text-xl tw-font-medium tw-text-black md:tw-text-2xl lg:tw-text-2xl"
//         >
//           {time}
//         </span>
//       </div>
//       <span className="tw-text-xs tw-font-light tw-text-slate-500 md:tw-text-sm lg:tw-text-base tw-mt-8">
//         {text}
//       </span>
//     </div>
//   );
// };

// export default ShiftingCountdown;

// const useTimer = (unit) => {
//   const [ref, animate] = useAnimate();

//   const intervalRef = useRef(null);
//   const timeRef = useRef(0);

//   const [time, setTime] = useState(0);

//   useEffect(() => {
//     intervalRef.current = setInterval(handleCountdown, 1000);

//     return () => clearInterval(intervalRef.current || undefined);
//   }, []);

//   const handleCountdown = async () => {
//     const end = new Date(COUNTDOWN_FROM);
//     const now = new Date();
//     const distance = +end - +now;

//     let newTime = 0;

//     if (unit === "Day") {
//       newTime = Math.floor(distance / DAY);
//     } else if (unit === "Hour") {
//       newTime = Math.floor((distance % DAY) / HOUR);
//     } else if (unit === "Minute") {
//       newTime = Math.floor((distance % HOUR) / MINUTE);
//     } else {
//       newTime = Math.floor((distance % MINUTE) / SECOND);
//     }

//     if (newTime !== timeRef.current) {
//       // Exit animation
//       await animate(
//         ref.current,
//         { y: ["0%", "-50%"], opacity: [1, 0] },
//         { duration: 0.35 }
//       );

//       timeRef.current = newTime;
//       setTime(newTime);

//       // Enter animation
//       await animate(
//         ref.current,
//         { y: ["50%", "0%"], opacity: [0, 1] },
//         { duration: 0.35 }
//       );
//     }
//   };

//   return { ref, time };
// };

// NOTE: Framer motion exit animations can be a bit buggy when repeating
// keys and tabbing between windows. Instead of using them, we've opted here
// to build our own custom hook for handling the entrance and exit animations

{
  /* <span className="tw-text-xs tw-font-light tw-text-slate-500 md:tw-text-sm lg:tw-text-base">
        {text}
      </span> */
}
// import { useAnimate } from "framer-motion";
// import { useEffect, useRef, useState } from "react";

// // NOTE: Change this date to whatever date you want to countdown to :)
// const COUNTDOWN_FROM = "2025-01-01";

// const SECOND = 1000;
// const MINUTE = SECOND * 60;
// const HOUR = MINUTE * 60;
// const DAY = HOUR * 24;

// const ShiftingCountdown = () => {
//   return (
//     <div className="tw-bg-gradient-to-br tw-from-yellow-400 tw-to-red-600 tw-p-4">
//       <div className="tw-mx-auto tw-flex tw-w-full tw-max-w-5xl tw-items-center tw-bg-white">
//         <CountdownItem unit="Day" text="days" />
//         <CountdownItem unit="Hour" text="hours" />
//         <CountdownItem unit="Minute" text="minutes" />
//         <CountdownItem unit="Second" text="seconds" />
//       </div>
//     </div>
//   );
// };

// const CountdownItem = ({ unit, text }) => {
//   const { ref, time } = useTimer(unit);

//   return (
//     <div className="tw-flex tw-h-18 tw-w-1/4 tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-border-r-[1px] tw-border-slate-200 tw-font-mono md:tw-h-36 md:tw-gap-2">
//       <div className="tw-relative tw-w-full tw-overflow-hidden tw-text-center">
//         <span
//           ref={ref}
//           className="tw-block tw-text-2xl tw-font-medium tw-text-black md:tw-text-4xl lg:tw-text-6xl xl:tw-text-7xl"
//         >
//           {time}
//         </span>
//       </div>
//       <span className="tw-text-xs tw-font-light tw-text-slate-500 md:tw-text-sm lg:tw-text-base">
//         {text}
//       </span>
//     </div>
//   );
// };

// export default ShiftingCountdown;

// // NOTE: Framer motion exit animations can be a bit buggy when repeating
// // keys and tabbing between windows. Instead of using them, we've opted here
// // to build our own custom hook for handling the entrance and exit animations
// const useTimer = (unit) => {
//   const [ref, animate] = useAnimate();

//   const intervalRef = useRef(null);
//   const timeRef = useRef(0);

//   const [time, setTime] = useState(0);

//   useEffect(() => {
//     intervalRef.current = setInterval(handleCountdown, 1000);

//     return () => clearInterval(intervalRef.current || undefined);
//   }, []);

//   const handleCountdown = async () => {
//     const end = new Date(COUNTDOWN_FROM);
//     const now = new Date();
//     const distance = +end - +now;

//     let newTime = 0;

//     if (unit === "Day") {
//       newTime = Math.floor(distance / DAY);
//     } else if (unit === "Hour") {
//       newTime = Math.floor((distance % DAY) / HOUR);
//     } else if (unit === "Minute") {
//       newTime = Math.floor((distance % HOUR) / MINUTE);
//     } else {
//       newTime = Math.floor((distance % MINUTE) / SECOND);
//     }

//     if (newTime !== timeRef.current) {
//       // Exit animation
//       await animate(
//         ref.current,
//         { y: ["0%", "-50%"], opacity: [1, 0] },
//         { duration: 0.35 }
//       );

//       timeRef.current = newTime;
//       setTime(newTime);

//       // Enter animation
//       await animate(
//         ref.current,
//         { y: ["50%", "0%"], opacity: [0, 1] },
//         { duration: 0.35 }
//       );
//     }
//   };

//   return { ref, time };
// };
